import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "+Q um Cardápio",
    paragraph:
      "Um cardápio digital para os consumidores, um aplicativo para a operação e um portal administrativo para os seus gerentes.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Módulo do Consumidor
                </div>
                <h3 className="mt-0 mb-12">Um belo Cardápio</h3>
                <p className="m-0">
                  Desperte o desejo pelo consumo com um cardápio atraente, fotos
                  bonitas e com o estilo da sua marca. O Menur é o cardápio mais
                  bonito do mercado.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://menur.app/exemplo">
                  <Image
                    src={require("./../../assets/images/features-cardapio.jpg")}
                    alt="Cardápio"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Módulo da Operação
                </div>
                <h3 className="mt-0 mb-12">Aplicativo Android e iOS</h3>
                <p className="m-0">
                  Os pedidos chegam para sua equipe no aplicativo Menur
                  Operação. Mudança do status dos pedidos, cancelamentos,
                  recebimento de pagamentos e remoção de produtos em falta. Tudo
                  isso e muito mais.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-app.jpg")}
                  alt="Aplicativo"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Módulo de gerenciamento
                </div>
                <h3 className="mt-0 mb-12">Portal Administrativo</h3>
                <p className="m-0">
                  Cadastro de produtos, ajustes no cardápio, geração de QRCodes,
                  ativação do pagamento Pix e configuração da integração com o
                  seu ERP. Essas e outras funcionalidades você encontra aqui.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <a href="https://meu.menur.app">
                  <Image
                    src={require("./../../assets/images/features-meu.jpg")}
                    alt="meu.menur.app"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
