import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import QuoteLeft from "../../assets/images/quote-left.svg";
import QuoteRight from "../../assets/images/quote-right.svg";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              {/* <img style={{ display: 'inline-block', marginBottom: 22, marginLeft: -25 }} alt="" src={QuoteLeft} /> */}
              {/* Cansei de <span className="text-color-primary">esperar</span> */}
              Atenda<span className="text-color-primary">+</span> com a{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                <span className="text-color-primary">mesma</span> equipe
              </span>
              {/* <img style={{ display: 'inline-block', marginBottom: 22, marginRight: -25 }} alt="" src={QuoteRight} /> */}
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Seu cliente{" "}
                <span style={{ whiteSpace: "nowrap" }}>pede e paga</span> pelo{" "}
                cardápio e os pedidos{" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  chegam no{" "}
                  <span
                    className="text-color-primary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    aplicativo
                  </span>
                </span>
                * dos atendendes,{" "}
                <span style={{ whiteSpace: "nowrap" }}>da cozinha</span> e{" "}
                <span style={{ whiteSpace: "nowrap" }}>do caixa</span>.
              </p>
              <p style={{ lineHeight: 1 }}>
                <span style={{ fontSize: 16, opacity: 0.5 }}>
                  *Integrado com seu sistema de gestão e com suas impressoras.
                </span>
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://api.whatsapp.com/send?phone=5571999294756&text=Oi!%20Quero%20saber%20mais%20sobre%20o%20Menur."
                  >
                    Contato WhatsApp
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="#price_section">
                    Quanto custa?
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="https://menur.app/exemplo"
              aria-controls="video-modal"
              // onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.jpg")}
                alt="Hero"
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
